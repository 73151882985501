.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.logo-cer {
  margin: 25px;
}

@media only screen and (max-width: 600px) {
  .servizio {
    display: none;
  }
}

.servizio {
  margin: 25px;
  flex-grow: 0;
}

.small-screen {
  transform: scale(0.7);
  margin: 15px; /* Scale the image down when the screen is less than 300px */
}